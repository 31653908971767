import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";

const Example = (props) => {
  const [smallBox, setSmallBox] = useState(false);

  const toggle = () => setSmallBox(!smallBox);

  return (
    <div style={{ zIndex: 50 }}>

      <Button
        size="sm"
        color="secondary"
        onClick={toggle}
        style={{ marginBottom: "3px"}}
      >
        Legend
      </Button>

      {smallBox && (
        <div style={{ backgroundColor: "#fff", borderRadius: "5px", borderColor: "slategray", borderWidth: "2px", borderStyle: "solid", width: "200px", zIndex: 50 }}>

          <Row>

            <Col xs={4} >
                {/* top to bottom */}
                <div style={{background:"linear-gradient(#000000, #FFFFFF)", height: "150px", width: "70px", borderRadius: "3px", margin: "4px"}}>
                </div>
            </Col>

            <Col xs={8}>
               <div style={{fontSize: "13px", fontWeight: "700", margin: "5px"}}>High Risk</div>
                        <div style={{height: "100px"}}><span style={{color: "transparent"}}> space </span></div>
               <div style={{fontSize: "13px", fontWeight: "700", margin: "5px"}}>Low Risk</div>
            </Col>

          </Row>
        </div>
      )}

    </div>
  );
};

export default Example;
