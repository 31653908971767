import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavLink, Row, Col } from "reactstrap";

import './AboutModal.css';
import RenderedMarkdownFromFile from './RenderedMarkdownFromFile'
import CallToAction from './CallToAction'

const AboutModal = (props) => {
  const { 
    modalId, 
    buttonLabel, 
    modalTitle, 
    modalBody, 
    modalBodyMarkdownFile, 
    openByDefault,
    onOpened,
    onClosed,
    onExit
  } = props;

  // console.log(modalId, openByDefault)

  if (openByDefault === undefined) {
    openByDefault = false
  }

  const [modal, setModal] = useState(
    openByDefault
    // localStorage.getItem("AboutModal") ? false : true
  );

  const toggle = () => setModal(!modal);

  return (
    <div className="CustomMapControl about-toggle">
      <NavLink
        className="CustomMapControl"
        style={{
          fontWeight: "500", 
          textTransform: "uppercase",
          color: "#757576"
        }}
        onClick={toggle}
      > 
        <div className="CustomMapControl">{buttonLabel}</div>
      </NavLink>
      <Modal 
        id={modalId} 
        isOpen={modal} 
        toggle={toggle} 
        centered={true} 
        size="lg"
        // onOpened={console.log("modal opened")}
        // onClosed={console.log("modal closed")}
        // onExit={console.log("modal exited")}
      >
        <ModalHeader toggle={toggle}>
          <span className="markdown-modal-title">{modalTitle}</span>
        </ModalHeader>
        <ModalBody> 
          {/* <ReactMarkdown children={modalBody}/>  */}
          <RenderedMarkdownFromFile importedMarkdownFile={modalBodyMarkdownFile}/>
          <hr></hr>
          <Row>
            <Col md={{size:10,offset:1}}>
              <CallToAction/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AboutModal;
