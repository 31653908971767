import React from "react";
import CSPLogo from "../images/CSP_primary.png";
import UWLogo from "../images/UWlogo.png";
import AFTLogo from "../images/AFTLogo.png";
import CallToAction from "./CallToAction";

const Footer = ({title}) => {
  return (
    <div className="mt-3">
      
      <div className="container">
        
        <div className="row p-3">
          <div className="col-md-10 offset-md-1">
            <span></span>
            <hr></hr>
            <CallToAction/>
          </div>
        </div>
      </div>
      
      <div className="container-fluid mt-3">
        <div className="row p-3">
          <div className="col d-flex justify-content-center p-1">
            <a href="https://www.farmland.org/">
              <img
                src={AFTLogo}
                width="280"
                height="60"
                alt="University of Wisconsin Logo"
              ></img>
            </a>
          </div>

          <div className="col d-flex justify-content-center p-1">
            <a href="https://www.csp-inc.org/">
              <img
                src={CSPLogo}
                width="230"
                height="55"
                alt="Conservation Science Partners Logo"
              ></img>
            </a>
          </div>

          <div className="col d-flex justify-content-center p-1">
            <a href="https://www.wisc.edu/">
              <img
                src={UWLogo}
                width="220"
                height="55"
                alt="University of Wisconsin Logo"
              ></img>
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          color: "white",
          backgroundColor: "#669947",
          marginBottom: "0px",
          width: "vw",
          minHeight: "50px",
          padding: "10px",
        }}
      >
        <div style={{ color: "white", fontSize: "13px", fontWeight: "500" }}>
          {title} is led by American Farmland Trust and conducted in partnership with Conservation Science Partners and the University of Wisconsin-Madison.
        </div>
      </div>

    </div>
  );
};

export default Footer;
