import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label, UncontrolledTooltip } from "reactstrap";
import { useWhatChanged } from '@simbathesailor/use-what-changed';


import { buildStyle, changeStyle } from "../utils/updateMapStyle";

import ScenarioSelect from "./ScenarioSelect";
import LegendFutDev from "./LegendFutDev";
import LegendClimate from "./LegendClimate";
import LegendErosion from "./LegendErosion";
import OpacitySlider from "./OpacitySlider";
import BasemapSelector from "./BaseMapSelector";

import { climateScenarioConfig } from "../data/climateScenarios"


const MapControl = (props) => {
  const {
    appId,
    mapId,
    climateLayerId, //passes ex: corn_suit
    climateSliderValue, //passes the year on time slider ex: 2020
    climateScenarioId, // passes either 26 or 85 as a string
    baseMapId,
    setBaseMapId,
    activeStyleId,
    setActiveStyleId,
    setActiveLayer,
    devScenarioId,
    setDevScenarioId,
    focusCityId,
    setFocusCityId,
    opacitySliderValue,
    setOpacitySliderValue,
    scenarioDropdownOpen,
    mapInfoButton,
    onChange,
    basemapLookup,
    showOverlays
  } = props;

  const [defaultMapStyle, setDefaultMapStyle] = useState();
  const [devMapTitle, setDevMapTitle] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [overlayVisibility, setOverlayVisibility] = useState({
    water_stress: false,
    bau_2040: false
  });

  // toggle overlays visibility
  const onOverlayVisibilityChange = (id, value) => {
    setOverlayVisibility({ ...overlayVisibility, [id]: value });
  };

  /************************************
   * buildStyle
   * Update the map style when the basemap changes
   */
  // useWhatChanged([baseMapId], 'basemap', 'buildStyle')
  useEffect(() => {
    // creates a new base
    buildStyle(appId, baseMapId)
      .then((style) => {
        setDefaultMapStyle(style);
        // setMapStyle(style);
      });

    switch (baseMapId) {
      case "Standard":
        setActiveStyleId("Present Day");
        // setDevScenarioId(devScenarioId);
        break;
      case "Satellite":
        setActiveStyleId("Satellite");
        // setDevScenarioId(devScenarioId);
        break;
      case "Present Day":
        setActiveStyleId("Present Day");
        // setDevScenarioId("fut_2040_lc");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseMapId]);

  /************************************
   * changeStyle
   * update the map style when map controls change
   */
  useEffect(() => {
    onChange(
      defaultMapStyle &&
      // mapStyle &&
      changeStyle({
        appId,
        defaultMapStyle,
        // mapStyle,
        overlayVisibility,
        activeStyleId,
        opacitySliderValue,
        climateLayerId,
        climateSliderValue,
        climateScenarioId,
        devScenarioId,
        baseMapId
      })
    );
  }, [
    defaultMapStyle,
    // mapStyle,
    overlayVisibility,
    activeStyleId,
    opacitySliderValue,
    climateLayerId,
    climateSliderValue,
    climateScenarioId,
    devScenarioId,
    baseMapId,
  ]);



  const toggle = () => {
    // console.log("tooltipOpen", tooltipOpen)
    setTooltipOpen(!tooltipOpen);
  }

  // const onClickHandler = (e) => {
  //   e.stopPropagation();
  //   props.download();
  // };

  const climateScenarioIds = Object.keys(climateScenarioConfig)

  // if (climateLayerId !== undefined) {
  //   console.log(climateLayerId, climateScenarioIds.includes(climateLayerId))
  // }


  return (
    <div className="map-control-wrapper">

      <Row className="map-control-overlay-top gx-1 d-flex justify-content-between">

        <Col xs={3} className="d-md-flex align-self-start">
          {props.displayClimateScenarios === false && (
            <ScenarioSelect
              id={props.rightMap ? "right" : "left"}
              devScenarioId={devScenarioId}
              setDevScenarioId={setDevScenarioId}
              devMapTitle={devMapTitle}
              setDevMapTitle={setDevMapTitle}
              focusCityId={focusCityId}
              setFocusCityId={setFocusCityId}
              scenarioDropdownOpen={scenarioDropdownOpen}
            />
          )}
        </Col>

        <Col xs={{ size: 6, offset: 3 }} className="d-md-flex align-self-start justify-content-end text-end">

          {props.rightMap === true && (

            <div
              className="basemap-legend-opacity-controls-wrapper end-col-justify"
            >

              <BasemapSelector
                baseMapId={baseMapId}
                setBaseMapId={setBaseMapId}
                basemapLookup={basemapLookup}
              />

              <OpacitySlider
                opacitySliderValue={opacitySliderValue}
                setOpacitySliderValue={setOpacitySliderValue}
              />

              {props.displayClimateScenarios === true && climateScenarioIds.includes(climateLayerId) && (
                <LegendClimate />
              )}
              {props.displayClimateScenarios === true && climateLayerId === "erisk" && (
                <LegendErosion />
              )}
              {props.displayClimateScenarios === false && (
                <LegendFutDev />
              )}


            </div>

          )}

        </Col>

      </Row>

      <Row className="map-control-overlay-bottom gx-0 mb-2 justify-content-between align-items-end" style={{ position: "absolute", bottom: 0 }}>
        
        <Col xs={11} md={10}>
          <div className="row">
            {/* OVERLAYS MAP CONTROL */}
            <div className="col-md-9 order-md-2 my-1 d-flex justify-content-center text-center">
              {props.displayClimateScenarios === false && devMapTitle && (
                <span className="map-control-title-text CustomMapControl">{devMapTitle}</span>
              )}
            </div>

            <div className="col-md-3 col-6 order-md-1 my-1">
              {showOverlays.length > 0 && (
                <div
                  className="CustomMapControl px-1 pt-1"
                  style={{
                    backgroundColor: "white",
                    borderRadius: ".2rem",
                    fontSize: "12px",
                    // fontWeight: "500",
                    // maxWidth: "50%"
                  }}
                >
                  {/* NOTE: move these kinds of controls a separate component */}
                  {showOverlays.includes("Water scarcity") ? (
                    <div>
                      <div id={`waterScarcityToggle-${mapId}`} className="CustomMapControl form-check">
                        <input
                          id={`waterScarcityCheck-${mapId}`}
                          type="checkbox"
                          className="CustomMapControl form-check-input"
                          checked={overlayVisibility["water_stress"]}
                          onChange={(event) => {
                            onOverlayVisibilityChange(
                              "water_stress",
                              event.target.checked
                            );
                          }}
                        />
                        <label 
                          className="form-check-label" 
                          for={`waterScarcityCheck-${mapId}`}
                          style={{lineHeight:"0.9rem"}}
                        >
                          Water scarcity
                        </label>
                      </div>
                      <UncontrolledTooltip
                        placement="auto"
                        autohide={false}
                        target={`waterScarcityToggle-${mapId}`}
                      >
                        Projected water scarcity in 2040 under a scenario with high greenhouse gas emissions. Scarcity is defined as the ratio of total water withdrawals to available renewable surface and groundwater supplies. Darker colors indicate higher levels of scarcity. Increasing water scarcity due to climate change and population growth will threaten the viability of agriculture in the future. Source:{" "}
                        <a
                          href="https://www.wri.org/applications/aqueduct/water-risk-atlas/#/?advanced=false&basemap=hydro&indicator=w_awr_def_tot_cat&lat=30&lng=-80&mapMode=view&month=1&opacity=0.5&ponderation=DEF&predefined=false&projection=absolute&scenario=optimistic&scope=baseline&timeScale=annual&year=baseline&zoom=3"
                          target="_blank"
                        >
                          Aqueduct™ Water Risk Atlas
                        </a>
                      </UncontrolledTooltip>
                    </div>
                  ) : (null)}
                  {showOverlays.includes("Projected Land Conversion and Flooding") ? (
                    <div>
                      <div id={`bauOverlayToggle-${mapId}`}className="CustomMapControl form-check">
                        <input
                          id={`bauOverlayCheck-${mapId}`}
                          type="checkbox"
                          className="CustomMapControl form-check-input"
                          checked={overlayVisibility["bau_2040"]}
                          onChange={(event) => {
                            onOverlayVisibilityChange(
                              "bau_2040",
                              event.target.checked
                            );
                          }}
                        />
                        <label 
                          className="form-check-label" 
                          for={`bauOverlayCheck-${mapId}`}
                          style={{lineHeight:"0.9rem"}}
                        >Projected land conversion and flooding
                        </label>
                      </div>
                      <UncontrolledTooltip
                        placement="auto"
                        autohide={false}
                        target={`bauOverlayToggle-${mapId}`}
                      >
                        Projected conversion to <span className="color--legend--urban">urban and highly developed</span> and <span className="color--legend--ldr">low-density residential</span> land uses, and <span className="color--legend--slr">projected flooding due to sea level rise</span>, in 2040 under a scenario with high greenhouse gas emissions. Source:{" "}
                        <a
                          href="https://development2040.farmland.org"
                          target="_blank"
                        >
                          development2040.farmland.org
                        </a>
                      </UncontrolledTooltip>
                    </div>
                  ) : (null)}
                </div>
              )}
            </div>


          </div>
        </Col>

        <Col xs={1} md={2}>
          <div className="about-toggle-wrapper CustomMapControl">
            {mapInfoButton}
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default MapControl;
