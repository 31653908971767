import React from "react";

const stateLookup = {"alabama": "al", "alaska": "ak", "arizona": "az", "arkansas": "ar", "california": "ca", "colorado": "co", "connecticut": "ct", "delaware": "de", "district of columbia": "dc", "florida": "fl", "georgia": "ga", "hawaii": "hi", "idaho": "id", "illinois": "il", "indiana": "in", "iowa": "ia", "kansas": "ks", "kentucky": "ky", "louisiana": "la", "maine": "me", "maryland": "md", "massachusetts": "ma", "michigan": "mi", "minnesota": "mn", "mississippi": "ms", "missouri": "mo", "montana": "mt", "nebraska": "ne", "nevada": "nv", "new hampshire": "nh", "new jersey": "nj", "new mexico": "nm", "new york": "ny", "north carolina": "nc", "north dakota": "nd", "ohio": "oh", "oklahoma": "ok", "oregon": "or", "pennsylvania": "pa", "rhode island": "ri", "south carolina": "sc", "south dakota": "sd", "tennessee": "tn", "texas": "tx", "utah": "ut", "vermont": "vt", "virginia": "va", "washington": "wa", "west virginia": "wv", "wisconsin": "wi", "wyoming": "wy"}
const makeReportUrl = (stateAbbrv) => `https://storage.googleapis.com/csp-fut2040.appspot.com/state-reports/FUT2040_${stateAbbrv.toUpperCase()}.pdf`

const DownloadStateReportButton = ({statsData}) => {

    let hasData = false;
    let stateAbbrv;
    let reportUrl;

    if (statsData.hasOwnProperty("stateName")) {
        if (statsData.stateName !== undefined && statsData.stateName !== "") {
            hasData = true
            stateAbbrv = stateLookup[statsData.stateName.toLowerCase()]
            reportUrl = makeReportUrl(stateAbbrv)
        } else {
            hasData = false
        }
    } else {
        hasData = false
    }

    return (
        hasData ? (
            <a className="btn btn-primary btn-sm" href={reportUrl} role="button" target="_blank">Download Report for {statsData.stateName}</a>
        ) : (
            null
        )
    )

    
}

export default DownloadStateReportButton;