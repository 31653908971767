import { fromJS, set, } from "immutable";
import API from "./API";

import {
  BASEMAP_CONFIG
} from '../data/layerConfig'

import LAYER_CONFIG from '../data/layerConfig.json'


/**
 * buildStyle takes a working Mapbox style sheet and adds custom layers to it 
 * from the layerConfig file. This function contains some business logic for 
 * adding metadata to those layers.
 * @param {*} baseMapId 
 * @returns 
 */
export const buildStyle = (appId, baseMapId) => {
  console.log("building", baseMapId, "style for", appId, "page")

  // get the config for spec'd basemap
  const baseMapConfig = BASEMAP_CONFIG[baseMapId]
  // Get the base map style from MapStyle.json into an Immutable object
  // const initialStyle = fromJS(MAP_STYLE);
  const initialStyle = fromJS(baseMapConfig.style)
  // rewrite the sprite URL
  // initialStyle['sprite'] = baseMapConfig.customSprites

  // get layers and sources from the style object
  const initialLayers = initialStyle.get("layers");
  let lyrs = initialLayers.toArray();
  const initialSources = initialStyle.get("sources");
  let srcs = initialSources.toObject();

  // Get the layer config for the spec'd app.
  // This tells us what layers we need to splice into the style
  const layerConfig = LAYER_CONFIG[appId];
  // layer class configurations within the layerConfig object
  ["overlay", "scenario", "basemap"].forEach((layerCls, i) => {
    // keys in the layer config are the layer classes
    // the class tag is used in the layer control and for positioning of layers
    // in order in the style
    // one of (overlay, scenario, basemap)

    // the layer sources config for the class:
    let layerConfigs = layerConfig[layerCls];
    // let printLayers = [];
    // each layer class object contains layer source objects
    layerConfigs.forEach((c, i) => {

      // console.log(c)

      let layerId = c.id // layer ID (https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#id)
      let layerVisibility = "none"; // default visibility (https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-background-visibility)
      let lyrUrl = c.url;

      // printLayers.push({
      //   id: s,
      //   url: lyrUrl,
      //   cls: layerCls
      // })      

      // create a new raster layer source for the style object
      let newSrc = {
        type: "raster",
        tileSize: 256,
        tiles: [lyrUrl]
      };
      // add the new source to the sources object in the style
      srcs[layerId] = newSrc;

      // create a raster layer associated with the source
      let newLyr = {
        id: layerId,
        source: layerId,
        type: "raster",
        layout: {
          visibility: layerVisibility
        },
        metadata: {
          style: appId,
          layerCls: layerCls
        }
      }

      if (c.label !== undefined) {
        newLyr.metadata.label = c.label;
      }

      // console.log(newLyr);
      // Splice the layer into the layers arrray at the config'd index
      lyrs.splice(baseMapConfig.insertOverlaysAtIndex, 0, newLyr);
      // console.log("inserted", lyrs[baseMapConfig.insertOverlaysAtIndex])    

    })

    // console.log(printLayers);



  })

  const finalSources = fromJS(srcs);
  const finalLayers = fromJS(lyrs);

  return new Promise((resolve, reject) => {
    let finalStyle = initialStyle.set("sources", finalSources).set("layers", finalLayers)
    // console.log(finalStyle.toJS())
    resolve(finalStyle)
    reject(initialStyle)
  })

}

export const changeStyle = (args) => {
  const {
    appId,
    defaultMapStyle, // Mapbox style object
    baseMapId, // the base map name
    overlayVisibility, // object of overlay layers Ids with visibility as boolean 
    opacitySliderValue, // opacity value for overlays and scenario alayers
    devScenarioId, // development scenario name / ID of scenario layer
    climateLayerId, // climate scenario focus ID (crop, range, etc)
    climateScenarioId, // climate scenario (26 or 85)
  } = args;

  // console.log(args)

  if (defaultMapStyle === undefined) {
    console.log("defaultMapStyle not yet defined")
    return
  }

  console.log("changing style")
  // return defaultMapStyle

  let layers = defaultMapStyle.get('layers').map(lyr => {

    // get layer props
    let layerId = lyr.get('id');
    let layerType = lyr.get('type');
    let layerCls = lyr.getIn(["metadata", "layerCls"], 'default');

    // if (layerCls !== 'default') { console.log(layerId, layerCls) }

    // ------------------------------------------
    // set visibility for basemaps

    if (layerCls === "basemap" && layerType === "raster") {
      // for the FutureDev App
      if (appId === "FutureDev") {
        // specifically for the Present Day landcover layer
        if (baseMapId == "Present Day" && layerId === "fut_2040_lc") {
          lyr = lyr.setIn(["layout", "visibility"], "visible")
        }
      }
    }

    // ------------------------------------------
    // set visibility for checkbox-controlled overlays

    if (layerCls == "overlay" && layerType === "raster") {

      let viz = overlayVisibility[layerId] ? "visible" : "none"
      // console.log("  viz", viz)
      // set visibility
      if (Object.keys(overlayVisibility).includes(layerId)) {
        lyr = lyr.setIn(["layout", "visibility"], viz)
      }

    }

    // ------------------------------------------
    // set visibility for scenarios
    if (layerCls == "scenario" && layerType === "raster") {
      if (layerId === devScenarioId) {
        // console.log("  vis", lyr.getIn(["layout", "visibility"]))
        lyr = lyr.setIn(["layout", "visibility"], "visible")
      }
      // handle climate scenario overlays
      if (climateLayerId !== undefined && climateScenarioId !== undefined) {
        if (layerId.startsWith(climateLayerId) && layerId.endsWith(climateScenarioId)) {
          // console.log(climateLayerId, layerId, "visible")
          lyr = lyr.setIn(["layout", "visibility"], "visible")
        } else {
          lyr = lyr.setIn(["layout", "visibility"], "none")
          // console.log("----", layerId, "none")
        }
      }

    }

    // ------------------------------------------
    // change opacity for scenarios and overlays
    // if (appId === "FutureDev") { 
      if (['scenario', 'overlay'].includes(layerCls)) {
        let opacity = opacitySliderValue * 0.01
        // console.log("  opacity", opacity)
        lyr = lyr.setIn(["paint", "raster-opacity"], opacity);
      }
    // }
    // if (appId === "ClimateImpact") { 
    //   if (['overlay'].includes(layerCls)) {
    //     let opacity = opacitySliderValue * 0.01
    //     // console.log("  opacity", opacity)
    //     lyr = lyr.setIn(["paint", "raster-opacity"], opacity);
    //   }
    // }    


    // if (layerCls !== 'default') {console.log(lyr.toJS())}

    return lyr

  })

  // console.log(layers.toJS())

  return defaultMapStyle.set("layers", layers);


  // let layers = defaultMapStyle.get("layers")
  //   // filter layers that match the function args
  //   .filter((layer) => {

  //     // get info about the layer
  //     let layerId = layer.get("id");
  //     let layerCls = layer.getIn(["metadata", "layerCls"]);

  //     // climate layerId (dynamically determined
  //     const climateLayerId = climateLayerId + "_change_" + 2040 + "_" + climateScenarioId;

  //     // if the layer matches any of these conditions, it is included for updates
  //     return (
  //       // an overlay layer that's visible
  //       overlayVisibility[layerId] || 
  //       // a climate scenario layer
  //       layerId === climateLayerId ||
  //       // a dev scenario laeyr 
  //       layerId === devScenarioId || 
  //       // it's a state or county boundary layer
  //       BOUNDARY_LAYER_STYLE_IDS.includes(layerId) ||
  //       // very specifically, the 2016 Land Cover Layer shown for the Present Day basemap 
  //       (layerId === "fut_2040_lc" && layerCls === "basemap" && baseMapId == "Present Day") 
  //     );
  //   }).map((layer) => {
  //     // update any the matching layers based on the function args as needed
  //     let layerCls = layer.getIn(["metadata", "layerCls"]);
  //     let layerType = layer.get("type");

  //     // Set opacity to scenario and overlay layers using opacitySliderValue
  //     // const newPaintValue = devScenarioId === "fut_2040_lc" ? 1 : opacitySliderValue * 0.01;
  //     if (["scenario", "overlay"].includes(layerCls) && layerType === "raster") {
  //       // layer = layer.setIn(["paint", "raster-opacity"], newPaintValue);
  //       layer = layer.setIn(["paint", "raster-opacity"], opacitySliderValue * 0.01);
  //     }
  //     return layer;
  //   });

}