import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from "react";
import ReactMapGL, {
  NavigationControl,
  Source,
  Layer
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import { Tooltip } from "reactstrap";

import MapControl from "./MapControl";

import fixList from '../data/fixList.json'
import { BOUNDARY_LAYER_STYLES } from '../data/layerConfig'
import { devScenarioConfig } from '../data/devScenarios'
const CITY_COORDINATES = devScenarioConfig.farmlandProtectionCities

/**********************************************************
 * Mapbox config
 */

let mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

mapboxgl.accessToken = mapboxToken

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/**********************************************************
 * Map Component. Wraps a React-Map-GL component.
 * @param {*} props 
 * @returns 
 */
const Map = (props) => {
  const {
    appId,
    mapId,
    viewport,
    setViewport,
    baseMapId,
    setBaseMapId,
    activeStyleId,
    setActiveStyleId,
    opacitySliderValue,
    setOpacitySliderValue,
    climateScenarioId,
    statsData,
    setStatsData,
    setStatisticsData,
    leftMap,
    rightMap,
    exportMap,
    defaultDevScenarioId,
    showWaterScarcity,
    downloadCompareMap,
    scenarioDropdownOpen,
    mapInfoButton,
    basemapLookup,
    showOverlays
  } = props;


  /********************************************************
   * Map state
   */

  const [mapStyle, setMapStyle] = useState("");
  const [activeLayer, setActiveLayer] = useState(props.overlays);
  const [devScenarioId, setDevScenarioId] = useState(defaultDevScenarioId);
  const [climateSliderValue, setClimateSliderValue] = useState(2020);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [focusCityId, setFocusCityId] = useState("");
  
  const maxZoom = climateScenarioId !== undefined ? 10 : 13;
  const mapRef = useRef();

  /********************************************************
   * EFFECT: Focus City
   */

  useEffect(() => {
    if (!mapRef.current || !CITY_COORDINATES[focusCityId]) return;
    setViewport({
      ...viewport,
      longitude: CITY_COORDINATES[focusCityId].lon,
      latitude: CITY_COORDINATES[focusCityId].lat,
      zoom: 6,
    });
  }, [focusCityId]);

  /********************************************************
   * LOG MAP STYLE UPDATE
   * (for debugging purposes)
   */
  // const prvMapStyle = useRef()
  // useEffect(() => {
  //   if (prvMapStyle.current !== undefined) {
  //     console.log("PREVIOUS:", prvMapStyle.current)
  //   }
  //   prvMapStyle.current = mapStyle
  //   if (mapStyle !== undefined) {
  //     console.log("NOW:", mapStyle)
  //   }
  // }, [mapStyle])

  /********************************************************
   * MAP EXPORT
   */

  const onMapLoad = useCallback(() => {
    // exportAsImage();
  }, []);

  const exportAsImage = async () => {
    let imageFileName = "map";
    if (leftMap) imageFileName = "left-map";
    if (rightMap) imageFileName = "right-map";
    const el = await document.querySelector(
      `.${imageFileName} .mapboxgl-canvas`
    );
    const image = el.toDataURL("image/png", 1.0);
    exportMap(image);
  };

  const downloadMapHandler = () => {
    downloadCompareMap();
  };

  /********************************************************
   * MAP CLICK HANDLING
   */

  const onMapClick = useCallback(event => {

    // disable map selection for the ClimateImpact page
    if (props.displayClimateScenarios) {
      return
    }

    const eventFeatures = event.features;
    // console.log("eventFeatures", eventFeatures);

    if (eventFeatures) {
      // console.log(eventFeatures)

      const stateFeature = eventFeatures.find(f => f.source === "stateData")
      const countyFeature = eventFeatures.find(f => f.source === "countyData")
      const stateData = stateFeature ? stateFeature.properties : {};
      const countyData = countyFeature ? countyFeature.properties : {};

      // console.log("stateData", stateData)
      // console.log("countyData", countyData)

      // WORKAROUND: https://github.com/csp-inc/aft2040/issues/57
      // this replaces the county name with another spec'd in fixList.json
      // (used to address unicode errors in the exported tiles)
      let countyName = countyData.NAME
      if (Object.keys(fixList.counties).includes(countyData.NAME)) {
        countyName = fixList.counties[countyData.NAME]
      }
      // console.log(countyData.NAME, fixList.counties, countyName);
      


      setStatisticsData({
        allState: stateData,
        allCounty: countyData,
        state: {
          STATE_NAME: stateData.STATE_NAME || "",
          ag_to_uhd_bau: stateData.ag_to_uhd_bau || 0,
          ag_to_ldr_bau: stateData.ag_to_ldr_bau || 0,
          ag_to_uhd_rs: stateData.ag_to_uhd_rs || 0,
          ag_to_ldr_rs: stateData.ag_to_ldr_rs || 0,
          ag_to_uhd_bbc: stateData.ag_to_uhd_bbc || 0,
          ag_to_ldr_bbc: stateData.ag_to_ldr_bbc || 0,
          ag_to_dev_bau: stateData.ag_to_dev_bau || 0,
          ag_to_dev_rs: stateData.ag_to_dev_rs || 0,
          ag_to_dev_bbc: stateData.ag_to_dev_bbc || 0,
        },
        county: {
          NAME: countyName || "",
          ag_to_uhd_bau: countyData.ag_to_uhd_bau || 0,
          ag_to_ldr_bau: countyData.ag_to_ldr_bau || 0,
          ag_to_uhd_rs: countyData.ag_to_uhd_rs || 0,
          ag_to_ldr_rs: countyData.ag_to_ldr_rs || 0,
          ag_to_uhd_bbc: countyData.ag_to_uhd_bbc || 0,
          ag_to_ldr_bbc: countyData.ag_to_ldr_bbc || 0,
          ag_to_dev_bau: countyData.ag_to_dev_bau || 0,
          ag_to_dev_rs: countyData.ag_to_dev_rs || 0,
          ag_to_dev_bbc: countyData.ag_to_dev_bbc || 0,
        },
      });

      // NOTE: this is for the climate app, which is currently not showing
      // anything from map clicks
      setStatsData({
        // State Data
        stateGeoId: stateData.GEOID || "",
        stateName: stateData.STATE_NAME || "",
        state_apple_26: stateData.mean_apple_suit_change_2040_26 || 0,
        state_apple_85: stateData.mean_apple_suit_change_2040_85 || 0 || 0,
        state_corn_26: stateData.mean_corn_suit_change_2040_26 || 0,
        state_corn_85: stateData.mean_corn_suit_change_2040_85 || 0,
        state_crop_26: stateData.mean_crop_suit_change_2040_26 || 0,
        state_crop_85: stateData.mean_crop_suit_change_2040_85 || 0,
        state_pasture_26: stateData.mean_pasture_suit_change_2040_26 || 0,
        state_pasture_85: stateData.mean_pasture_suit_change_2040_85 || 0,
        state_range_85: stateData.mean_range_suit_change_2040_26 || 0,
        state_range_26: stateData.mean_range_suit_change_2040_85 || 0,
        state_wWheat_85: stateData.mean_wWheat_suit_change_2040_26 || 0,
        state_wWheat_26: stateData.mean_wWheat_suit_change_2040_85 || 0,
        //County data
        countyName: countyName || "",
        countyGeoId: countyData.GEOID || "",
        county_apple_26: countyData.mean_apple_suit_change_2040_26 || 0,
        county_apple_85: countyData.mean_apple_suit_change_2040_85 || 0,
        county_corn_26: countyData.mean_corn_suit_change_2040_26 || 0,
        county_corn_85: countyData.mean_corn_suit_change_2040_85 || 0,
        county_crop_26: countyData.mean_crop_suit_change_2040_26 || 0,
        county_crop_85: countyData.mean_crop_suit_change_2040_85 || 0,
        county_pasture_26: countyData.mean_pasture_suit_change_2040_26 || 0,
        county_pasture_85: countyData.mean_pasture_suit_change_2040_85 || 0,
        county_range_85: countyData.mean_range_suit_change_2040_26 || 0,
        county_range_26: countyData.mean_range_suit_change_2040_85 || 0,
        county_wWheat_85: countyData.mean_wWheat_suit_change_2040_26 || 0,
        county_wWheat_26: countyData.mean_wWheat_suit_change_2040_85 || 0,
      });

      // const selectedCounty = (countyData.GEOID) || "";
      // // const countyFilter = useMemo(() => ["in", "GEOID", selectedCounty]);
      // setCountyFilter(["in", "GEOID", selectedCounty])
      // console.log("selectedCounty", selectedCounty)

      // const selectedState = (stateData.STATE_NAME) || "";
      // // const stateFilter = useMemo(() => ["in", "STATE_NAME", selectedState]);
      // setStateFilter(["in", "STATE_NAME", selectedState])
      // console.log("selectedState", selectedState)



      //ZOOMS INTO SELECTED COUNTY

      // const feature = event.features[0];
      // if (feature) {
      //   // calculate the bounding box of the feature
      //   const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      //   // construct a viewport instance from the current state
      //   const vp = new WebMercatorViewport(viewport);
      //   const {longitude, latitude, zoom} = vp.fitBounds(
      //     [
      //       [minLng, minLat],
      //       [maxLng, maxLat]
      //     ],
      //     {
      //       padding: 200
      //     }
      //   );
      //   setViewport({
      //     ...viewport,
      //     longitude,
      //     latitude,
      //     zoom,
      //     transitionInterpolator: new LinearInterpolator({
      //       around: [event.offsetCenter.x, event.offsetCenter.y]
      //     }),
      //     transitionDuration: 1000
      //   });
      // }

    } else {
      setStatisticsData({})
      setStatsData({})
      // setCountyFilter(["in", "GEOID", ""])
      // setStateFilter(["in", "STATE_NAME", ""])

    }


  }, []);

  /********************************************************
   * STATE AND COUNTY FILTERS
   */

  const selectedCounty = (statsData && statsData.countyGeoId) || "";
  const countyFilter = useMemo(() => ["in", "GEOID", selectedCounty], [selectedCounty]);
  // console.log("selectedCounty", selectedCounty, countyFilter)

  const selectedState = (statsData && statsData.stateName) || "";
  const stateFilter = useMemo(() => ["in", "STATE_NAME", selectedState], [selectedState]);
  // console.log("selectedState", selectedState, stateFilter)

  /********************************************************
   * TOOLTIP 
   */

  const toggle = () => setTooltipOpen(!tooltipOpen);


  /** Consider the following block later
  useEffect(() => {
    if (activeStyleId === 'Present Day') {
      const presentDayStyle = Immutable.fromJS({
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [
              "https://storage.googleapis.com/csp-fut2040.appspot.com/tiles/FUT2040_LC/{z}/{x}/{y}.png",
            ],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "test",
            type: "raster",
            source: "raster-tiles",
            "layout": {
              "visibility": "visible"
            },
            "source-layer": "state",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
        center: [120, 30],
        zoom: 9,
      });
      setMapStyle(presentDayStyle);
    }
  }, [activeStyleId]);
  */

  return (
    <>
      <ReactMapGL
        reuseMaps
        ref={mapRef}
        id={`${mapId}`}
        className={`${mapId}`}
        {...viewport}
        minZoom={3}
        maxZoom={maxZoom}
        onMove={evt => setViewport(evt.viewState)}
        width="100%"
        height="100%"
        mapboxAccessToken={mapboxToken}
        mapStyle={mapStyle}
        styleDiffing
        preserveDrawingBuffer
        ayncRender={true}
        onClick={onMapClick}
        onLoad={onMapLoad}
        interactiveLayerIds={["countyData", "stateData"]}
        scrollZoom={true}
      >
        <NavigationControl
          position={'bottom-right'}
          showCompass={false}
        />

        {/* Consider custom tile overlay later. 
        NOTE: Implementing this requires managing layer order relative to 
        county and state layers */}
        {/* {baseMapId === "Present Day" && (
          <Source
            id="FUT2040_LC_TILES"
            type="raster"
            tiles={[
              "https://storage.googleapis.com/csp-fut2040.appspot.com/tiles/FUT2040_LC/{z}/{x}/{y}.png",
            ]}
          >
            <Layer 
              id={'FUT2040_LC_LAYER'}
              type={'raster'}
              source={'raster-tiles'}
              tileSize={256}
            />
          </Source>
        )} */}

        <Source
          id="countyData"
          type="vector"
          tiles={[
            "https://csp-fut2040.appspot.com.storage.googleapis.com/tiles/counties/{z}/{x}/{y}.pbf",
          ]}
        >
          <Layer {...BOUNDARY_LAYER_STYLES.countyData} />
          {/* <Layer {...BOUNDARY_LAYER_STYLES.highlightCountyLayerFillStyle} filter={countyFilter} /> */}
          {/* <Layer {...BOUNDARY_LAYER_STYLES.highlightCountyLayerOutlineShadowStyle} filter={countyFilter} /> */}
          <Layer {...BOUNDARY_LAYER_STYLES.highlightCountyLayerOutline1Style} filter={countyFilter} />
          {/* <Layer {...highlightCountyLayerOutline2Style} filter={countyFilter} /> */}

        </Source>

        <Source
          id="stateData"
          type="vector"
          tiles={[
            "https://csp-fut2040.appspot.com.storage.googleapis.com/tiles/state/{z}/{x}/{y}.pbf",
          ]}
        >
          <Layer {...BOUNDARY_LAYER_STYLES.stateData} />
          {/* <Layer {...BOUNDARY_LAYER_STYLES.highlightStateLayerFillStyle} filter={stateFilter} /> */}
          <Layer {...BOUNDARY_LAYER_STYLES.highlightStateLayerOutline1Style} filter={stateFilter} />
          {/* <Layer {...BOUNDARY_LAYER_STYLES.highlightStateLayerOutline2Style} filter={stateFilter} /> */}
        </Source>

        <MapControl
          // appID
          appId={appId}
          // map component display boolean
          mapId={mapId}
          leftMap={props.leftMap}
          rightMap={props.rightMap}
          // basemap name
          baseMapId={baseMapId}
          setBaseMapId={setBaseMapId}
          // activeStyleId (for the FutureDev app, this seems to be the basemap name)
          activeStyleId={activeStyleId}
          setActiveStyleId={setActiveStyleId}
          // scenario name
          devScenarioId={devScenarioId}
          setDevScenarioId={setDevScenarioId}
          // focus city (controls pan/zoom, overlay)
          focusCityId={focusCityId}
          setFocusCityId={setFocusCityId}
          // layer opacity slider value
          opacitySliderValue={props.opacitySliderValue}
          setOpacitySliderValue={props.setOpacitySliderValue}
          // Mapbox map style object (as Immutable-JS object)
          mapStyle={mapStyle}
          setMapStyle={setMapStyle}
          onChange={setMapStyle}
          // FutureDev UI config
          showWaterScarcity={showWaterScarcity}
          scenarioDropdownOpen={scenarioDropdownOpen}
          download={downloadMapHandler}
          mapInfoButton={mapInfoButton}

          // Climate Scenario UI config
          // min={props.min}
          // max={props.max}
          // step={props.step}
          // marks={props.marks}
          // years={props.years}
          displayClimateScenarios={props.displayClimateScenarios}
          climateLayerId={props.climateLayerId}
          climateSliderValue={climateSliderValue}
          climateScenarioId={climateScenarioId}
          // ???
          // layers={props.layers}
          overlays={props.overlays}
          showOverlays={showOverlays}

          basemapLookup={basemapLookup}
        // previously included but not used:
        // activeLayer={activeLayer}
        // setActiveLayer={setActiveLayer}          

        />

      </ReactMapGL>



    </>
  );
};

export default Map;
