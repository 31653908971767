import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MapProvider } from 'react-map-gl';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

//components
import Navbar from '../components/Navbar';
import Map from '../components/Map';
import Footer from '../components/Footer';
import ClimateGraphs from '../components/ClimateGraphs';
// import ClimateButtonBar from '../components/ClimateButtonBar';
import ClimateButtonBar from '../components/ClimateButtonBar'
import {climateScenarioConfig} from '../data/climateScenarios';
import aboutModalMarkdownFile from "../docs/about-climate.md"
import AboutModal from '../components/AboutModal';
import { BASEMAP_CONTROL_CONFIG, LAYER_CONFIG } from '../data/layerConfig'

const appId = "ClimateImpact"
const appTitle = 'Climate & Rainfed Agriculture'
const aboutModaltitle = appTitle;
const aboutModalBody = ""

const ClimateImpactPage = () => {

  const [viewport, setViewport] = useState({
    latitude: 38.8,
    longitude: -96.0,
    zoom: 3.3,
    bearing: 0,
    pitch: 0,
  });
  const [baseMapId, setBaseMapId] = useState("Standard");
  const [activeStyleId, setActiveStyleId] = useState('light');
  const [opacitySliderValue, setOpacitySliderValue] = useState(100);
  const [statisticsData, setStatisticsData] = useState({});  
  const [mapTitle, setMapTitle] = useState('Cropland Forecast');
  const [climateLayerId, setClimateLayerId] = useState('crop');
  const [statsData, setStatsData] = useState({});
  const [leftMapImage, setLeftMapImage] = useState(null);
  const [rightMapImage, setRightMapImage] = useState(null);
  const [isDownloadTriggered, setIsDownloadTriggered] = useState(false);

  // const displayTimeSlider = true;
  const displayClimateScenarios = true;
  const leftMap = true;
  const rightMap = true;
  const showOverlays = LAYER_CONFIG[appId].overlay.map(o => o.label);

  useEffect(() => {
    localStorage.setItem('AboutModal', true);
  }, []);

  // useEffect(() => {
  //   if (isDownloadTriggered && leftMapImage && rightMapImage) {
  //     exportAsImage();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isDownloadTriggered, leftMapImage, rightMapImage]);

  // const exportAsImage = () => {
  //   downloadPDF();
  //   downloadIndividualMaps();
  //   setIsDownloadTriggered(false);
  // };

  // const downloadPDF = async () => {
  //   const leftMapContainerEL = await document.querySelector('#map-section .left-map .mapboxgl-canvas-container');
  //   leftMapContainerEL.firstChild.style.display = 'none';
  //   const leftImgEl = document.createElement('img');
  //   leftImgEl.src = leftMapImage;
  //   leftMapContainerEL.appendChild(leftImgEl);

  //   const rightMapContainerEL = await document.querySelector('#map-section .right-map .mapboxgl-canvas-container');
  //   rightMapContainerEL.firstChild.style.display = 'none';
  //   const rightImgEl = document.createElement('img');
  //   rightImgEl.src = rightMapImage;
  //   rightMapContainerEL.appendChild(rightImgEl);

  //   const pdf = new jsPDF('landscape', 'pt', 'a4');
  //   const mapSectionEl = document.getElementById('map-section');
  //   const mapSectionData = await html2canvas(mapSectionEl);
  //   const mapSectionImage = mapSectionData.toDataURL('image/png', 1.0);
  //   const imgProperties = pdf.getImageProperties(mapSectionImage);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   pdf.addImage(mapSectionImage, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //   pdf.save('compare_map.pdf');

  //   leftMapContainerEL.firstChild.style.display = 'block';
  //   rightMapContainerEL.firstChild.style.display = 'block';
  //   leftMapContainerEL.removeChild(leftImgEl);
  //   rightMapContainerEL.removeChild(rightImgEl);
  // };

  // const downloadIndividualMaps = async () => {
  //   const maps = [
  //     {
  //       fileName: 'left-map',
  //       blob: leftMapImage,
  //     },
  //     {
  //       fileName: 'right-map',
  //       blob: rightMapImage,
  //     },
  //   ];
  //   maps.forEach(async map => {
  //     await downloadImage(map.blob, map.fileName);
  //   });
  // };

  // const downloadImage = (blob, fileName) => {
  //   const link = window.document.createElement('a');
  //   link.style = 'display: none;';
  //   link.download = fileName;
  //   link.href = blob;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   link.remove();
  // };

  // const downloadMapHandler = () => {
  //   setIsDownloadTriggered(true);
  // };

  // const exportLeftMapHandler = (image) => {
  //   setLeftMapImage(image);
  // };

  // const exportRightMapHandler = (image) => {
  //   setRightMapImage(image);
  // };

  return (
    <MapProvider>
      <nav>
        {/* <Topbar /> */}
        <Navbar
          title={appTitle}
          buttonLabel={'ABOUT'}
          modalTitle={'Explore the Future of Farmland'}
          includeHowTo={false}
          aboutModalButton={
            <AboutModal
              modalId={"nav-about-modal"}
              buttonLabel={"ABOUT"}
              modalTitle={aboutModaltitle}
              modalBody={aboutModalBody}
              modalBodyMarkdownFile={aboutModalMarkdownFile}
              openByDefault={true}              
            />
          }
          appName={'Future Development Scenarios'}
          linkToApp={'/ClimateImpact'}
        />
      </nav>
      <Container fluid>
        <Row>
          <Col>
            <ClimateButtonBar
                setMapTitle={setMapTitle}
                setClimateOverlayId={setClimateLayerId}
                climateLayerId={climateLayerId}
                // download={downloadMapHandler}
              />
              {/* {mapTitle} */}
          </Col>
        </Row>
        <Row id="map-section" className="flex-column-reverse flex-lg-row pe-2 pe-sm-0 pe-md-0 pe-lg-0 pe-xl-0">
          <Col lg={6} xs={12} className="map-column">
            <div className='map-wrapper'>
              <Map
                appId={appId}
                // map identification
                mapId={"left-map"}
                leftMap={leftMap}
                // map viewport state (for synchronization)
                viewport={viewport}
                setViewport={setViewport}              
                // "basemap" name (used to select subset of layer from seed style fo)
                baseMapId={baseMapId}
                setBaseMapId={setBaseMapId}
                basemapLookup={BASEMAP_CONTROL_CONFIG.slice(0,2)}
                //climate scenario / crop
                displayClimateScenarios={displayClimateScenarios}
                climateLayerId={climateLayerId} // Crop for climate scenario
                climateScenarioId={'85'} // climate scenario ID (RCP)
                mapTitle={mapTitle} // title for climate scenario
                setMapTitle={setMapTitle}
                // "activeStyleId" (used to select subset of layers from style for overlays in the Climate app)
                activeStyleId={activeStyleId}
                setActiveStyleId={setActiveStyleId}
                // opacity slider state (for overlays and scenario layers)
                opacitySliderValue={opacitySliderValue}
                setOpacitySliderValue={setOpacitySliderValue}
                // overlays
                overlays={'Water scarcity'}
                showOverlays={showOverlays}
                showWaterScarcity={false}
                //RCP

                //OnClick Stats Data
                statsData={statsData}
                setStatsData={setStatsData}
                setStatisticsData={setStatisticsData}
              />              
            </div>
            <div className='p-2'>
              <h5 id="currentClimateTrajectory">
                Change in growing conditions for  {climateScenarioConfig[climateLayerId].label.toLowerCase()} with HIGH EMISSIONS climate trajectory, 2020-2040
              </h5>
              <p>
                Assumes greenhouse gas emissions continue to rise throughout this century, which results in a global temperature rise of 4.7-8.6º F for 2081-2100 compared to 1986-2005. (<a href="https://science2017.globalchange.gov/chapter/4/">Based on Representative Concentration Pathway 8.5</a>)              
              </p>
            </div>
          </Col>
          <Col
            lg={12}
            className="mobile-scroll-area">
          </Col>          
          <Col lg={6} xs={12} className="map-column">
            <div className='map-wrapper'>
              <Map
                appId={appId}
                // map identification
                mapId={"right-map"}
                rightMap={rightMap}
                // map viewport state (for synchronization)
                viewport={viewport}
                setViewport={setViewport}              
                // "basemap" name (used to select subset of layer from seed style fo)
                baseMapId={baseMapId}
                setBaseMapId={setBaseMapId}
                basemapLookup={BASEMAP_CONTROL_CONFIG.slice(0,2)}
                //climate scenario / crop
                displayClimateScenarios={displayClimateScenarios}
                climateLayerId={climateLayerId} // Crop for climate scenario
                climateScenarioId={'26'}
                mapTitle={mapTitle} // title for climate scenario
                setMapTitle={setMapTitle}
                // "activeStyleId" (used to select subset of layers from style for overlays in the Climate app)
                activeStyleId={activeStyleId}
                setActiveStyleId={setActiveStyleId}
                // opacity slider state (for overlays and scenario layers)
                opacitySliderValue={opacitySliderValue}
                setOpacitySliderValue={setOpacitySliderValue}
                // overlays
                overlays={'Water scarcity'}
                showOverlays={showOverlays}
                showWaterScarcity={false}
                
                //OnClick Stats Data
                statsData={statsData}
                setStatsData={setStatsData}
                setStatisticsData={setStatisticsData}
              />
            </div>
            <div className='p-2'>
              <h5 id="aggressiveClimateAction">
              Change in growing conditions for {climateScenarioConfig[climateLayerId].label.toLowerCase()} with AGGRESSIVE climate action, 2020-2040
              </h5>
              <p>
                Assumes greenhouse gas emissions start declining rapidly by 2020, which limits global temperature rise to 0.5-1.3º F for 2081-2100 compared to 1986-2005. (<a href="https://science2017.globalchange.gov/chapter/4/">Based on Representative Concentration Pathway 2.6</a>)
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row id="reporting-wrapper">
          
          <Col
            lg={{ size: 10, offset: 1 }}
            className="mt-4"
            style={{ minHeight: "350px" }}
          >
              <ClimateGraphs
                //OnClick Stats Data
                statsData={statsData}
                climateLayerId={climateLayerId}
              />
          </Col>
        </Row> */}
      </Container>



      <footer>
        <Footer title={appTitle}/>
      </footer>
    </MapProvider>
  );
};

export default ClimateImpactPage;
