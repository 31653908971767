import React from "react";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const PrettoSlider = withStyles({
  root: {
    color: "#fff",
    height: 1,
    paddingTop: "5px",
    paddingBottom: "10px",
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#669947",
    border: "2px solid currentColor",
    marginTop: -6,
    marginLeft: -10,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50%)",
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const OpacitySlider = (props) => {

  const sliderValue = props.opacitySliderValue
  const setSliderValue = props.setOpacitySliderValue

  const handleOnChange = (event, value) => {
    // console.log(event)
    setSliderValue(value)
  }

  return (
    <div
      className="opacity-slider-wrapper map-control-component-wrapper CustomMapControl"
      style={{
        backgroundColor: "#697179",
        paddingTop: "5px",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: ".2rem",
        width: "100px",
        zIndex: 50,
      }}
    >
      <div className="yearSlider CustomMapControl" id={props.id}>
        <PrettoSlider
          id="slider"
          className="CustomMapControl"
          min={0}
          max={100}
          step={1}
          value={sliderValue}
          onChange={handleOnChange}
        />
      </div>
    </div>
  )
}

export default OpacitySlider;

