import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledPopover as Popover,
  PopoverBody,
  Row, Col
} from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfo } from "@fortawesome/free-solid-svg-icons";
// import AboutModal from "./AboutModal";

import { devScenarioConfig } from '../data/devScenarios'
let futureDev = devScenarioConfig.futureDev
let farmlandCities = devScenarioConfig.farmlandProtectionCities

const ScenarioSelect = (props) => {
  const {
    devMapTitle,
    setDevMapTitle,
    devScenarioId,
    setDevScenarioId,
    focusCityId,
    setFocusCityId,
    scenarioDropdownOpen,
  } = props;
  const [dropdownOpen, setOpen] = useState(scenarioDropdownOpen ? true : false);

  // console.log(devScenarioId, focusCityId);

  const toggle = () => setOpen(!dropdownOpen);

  const selectFocusCityHandler = (event) => {
    setDevScenarioId("fp_2040");
    setDevMapTitle(event.target.innerHTML);
    setFocusCityId(event.target.name);
  };


  return (
    <ButtonDropdown
      className="CustomMapControl"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        caret
        style={{ backgroundColor: "#669947", borderColor: "transparent" }}
        className="CustomMapControl scenario-select-dropdown-toggle"
        type="button"
        size="sm"
        data-bs-auto-close="inside"
      >
        Select a Scenario
      </DropdownToggle>

      <DropdownMenu
        className="p-1"
        id={props.id}
        style={{ position: "relative", width: "285px" }}
        container="body"
      >
        <h6 style={{ textAlign: "center", marginTop: "10px" }}>
          Select a Scenario to Compare
        </h6>


        {/* ----------------------------------------------------------------
         * 2016
         */}

        <DropdownItem
          style={{ fontSize: "16px", justifyContent: "center" }}
          header
        >
          2016
        </DropdownItem>

        <div className="m-1">
          <DropdownItem
            id="button--scenario--baseline--btn"
            className="btn btn-secondary btn-sm py-2 text-center" // this makes DropdownItem look like a Button
            onClick={(event) => {
              setDevMapTitle(`2016 ${event.target.innerHTML}`);
              // setDevScenarioId(event.target.name);
              setDevScenarioId(null)
            }}
            name="fut_2040_lc"
            style={{
              backgroundColor: "#f6ab4d",
              color: "white",
              fontWeight: (devScenarioId === "fut_2040_lc"|| devScenarioId === null) ? "bold" : "normal",
              borderStyle: "solid",
              borderColor: (devScenarioId === "fut_2040_lc"|| devScenarioId === null) ? "#669947" : "transparent",
              borderWidth: 3,
            }}
            size="sm"
            
          >
            Baseline
          </DropdownItem>
        </div>

        {/* ----------------------------------------------------------------
         * 2040
         */}

        <DropdownItem style={{ fontSize: "16px" }} header>
          2040
        </DropdownItem>

        {Object.keys(futureDev).map((k, i) => (
          <div className="m-1" key={`future-btn-${i}-${k}`}>
            <DropdownItem
              id={`${props.id}-${k}-btn`}
              className="btn btn-secondary btn-sm py-2 text-center" // this makes DropdownItem look like a Button
              onClick={(event) => {
                setDevMapTitle(`2040 ${futureDev[k].label}`);
                setDevScenarioId(k);
              }}
              name={k}
              style={{
                color: "white",
                backgroundColor: futureDev[k].backgroundColor,
                fontWeight: (devScenarioId === k) ? "bold" : "normal",
                borderStyle: "solid",
                borderColor: devScenarioId === k ? "#669947" : "transparent",
                borderWidth: 3,
              }}
              size="sm"
              
            >
              {futureDev[k].label}
            </DropdownItem>
            <Popover
              trigger="hover"
              placement="right"
              id={`${props.id}-${k}-popover`}
              target={`${props.id}-${k}-btn`}
              style={{ position: "relative" }}
            >
              <PopoverBody>{futureDev[k].tooltip}
              </PopoverBody>
            </Popover>
          </div>
        ))}


        {/* ----------------------------------------------------------------
         * FARMLAND PROTECTION
         */}

        <DropdownItem style={{ fontSize: "16px" }} header>
          Farmland Protection
        </DropdownItem>

        <Row className="g-0">

          {Object.keys(farmlandCities).map((k, i) => (
            <Col xs={6} key={`city-btn-${i}-${k}`}>
              <div className="m-1">
                <DropdownItem
                  id={`${props.id}-${k}-btn`}
                  className="btn btn-secondary btn-sm py-2 text-center" // this makes DropdownItem look like a Button
                  onClick={(e) => {selectFocusCityHandler(e)}}
                  name={k}
                  style={{
                    color: "white",
                    fontWeight: (focusCityId === k && devScenarioId === 'fp_2040') ? "bold" : "normal",
                    backgroundColor: "#ab1c2f",
                    borderStyle: "solid",
                    borderColor: (focusCityId === k && devScenarioId === 'fp_2040') ? "#669947" : "transparent",
                    borderWidth: 3,
                  }}
                  size="sm"
                  
                >
                  {farmlandCities[k].label}
                </DropdownItem>
                <Popover
                  trigger="hover"
                  placement="right"
                  id={`${props.id}-${k}-popover`}
                  target={`${props.id}-${k}-btn`}
                  style={{ position: "relative" }}
                >
                  <PopoverBody>
                    An example of what it could look like to add permanent protection of farmland to the Better Built Cities scenario. Only available for selected metropolitan areas.
                  </PopoverBody>
                </Popover>
              </div>
            </Col>
          ))}
        </Row>

      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ScenarioSelect;
