/**
 * layerConfig.js
 * 
 * contains constants used to build out the various layer groups in the map:
 * overlays, scenarios, and basemaps
 */

/**
 * Mapbox Style Sheets
 * **complete** style sheets used for custom basemaps
 */
 import MAP_STYLE__SLITE from "../data/mapbox-satellite/style.json"
 import MAP_STYLE__LIGHT from "../data/mapbox-light/style.json"
 import MAP_STYLE__FUTLT from "../data/mapbox-futlight/style.json"

/**
 * Layer configuration file
 * Contains specification for custom sources for basemaps, scenarios, and 
 * overlays used for each app.
 */
import layerConfig from "../data/layerConfig.json"
export const LAYER_CONFIG = layerConfig;

/**
 * Basemaps config:
 * spec source and metadata for the Mapbox basemaps
 */
export const BASEMAP_CONFIG = {
  //"Name/ID of style": {
  //  style: import Mabpx style JSON,
  //  insertOverlaysAtIndex: where to insert scenario and then overlays within the mapbox style
  //  customSprites: path to mapbox style sprites
  //  label: used for basemap selector button
  //}
  "Standard": {
    style: MAP_STYLE__LIGHT,
    insertOverlaysAtIndex: 12,
    customSprites: "/client/public/assets/mapbox-sprites",
    label:"Standard"
  },
  "Satellite": {
    style: MAP_STYLE__SLITE,
    insertOverlaysAtIndex: 2,
    customSprites: "/client/public/assets/mapbox-sprites",
    label:"Satellite"
  },
  "Present Day": {
    style: MAP_STYLE__FUTLT,
    insertOverlaysAtIndex: 12,
    customSprites: "/client/public/assets/mapbox-sprites",
    label: "2016 Land Cover"
  }
}

export const BASEMAP_CONTROL_CONFIG = [
  //e.g.,: [layer reference, label for button]
  ["Standard", "Standard"],
  ["Satellite", "Satellite"],
  ["Present Day", "2016 Land Cover"]
];

/**
 * Boundary Layer styles
 * 
 * These are style specs for individual boundary layers
 */
 export const BOUNDARY_LAYER_STYLES = {

  highlightCountyLayerFillStyle: {
    "id": "highlightCountyLayerFillStyle",
    "source": "countyData",
    "source-layer": "counties",
    "type": "fill",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-outline-color": "#0d6efd",
      "fill-color": "hsla(0, 0%, 0%, 0)"
    }
  },
  highlightCountyLayerOutline1Style: {
    "id": "highlightCountyLayerOutline1Style",
    "source": "countyData",
    "source-layer": "counties",
    "type": "line",
    "layout": { "visibility": "visible" },
    // "paint": {"line-width": 5, "line-color": "hsl(181, 100%, 50%)", "line-blur": 2}
    "paint": { "line-width": 3, "line-color": "#0d6efd", "line-blur": 0 }

  },
  highlightCountyLayerOutline2Style: {
    "id": "highlightCountyLayerOutline2Style",
    "source": "countyData",
    "source-layer": "counties",
    "type": "line",
    "layout": { "visibility": "visible" },
    "paint": { "line-width": 1, "line-color": "#fff", "line-blur": 0.75 },
  },
  highlightCountyLayerOutlineShadowStyle: {
    "id": "highlightCountyLayerOutlineShadowStyle",
    "source": "countyData",
    "source-layer": "counties",
    "type": "line",
    "layout": { "visibility": "visible" },
    "paint": {
      "line-width": 6, "line-color": "rgba(0,0,0,0.4)", "line-blur": 2, "line-translate": [2, 2]
    },
  },
  highlightStateLayerFillStyle: {
    "id": "highlightStateLayerFillStyle",
    "source": "stateData",
    "source-layer": "state",
    "type": "fill",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-outline-color": "#c72c16",
      "fill-color": "hsla(0, 0%, 0%, 0)"
    }
  },
  highlightStateLayerOutline1Style: {
    "id": "highlightStateLayerOutline1Style",
    "source": "stateData",
    "source-layer": "state",
    "type": "line",
    "layout": { "visibility": "visible" },
    "paint": { "line-width": 3, "line-color": "#c72c16", "line-blur": 1 }
  },
  highlightStateLayerOutline2Style: {
    "id": "highlightStateLayerOutline2Style",
    "source": "stateData",
    "source-layer": "state",
    "type": "line",
    "layout": { "visibility": "visible" },
    "paint": { "line-width": 1, "line-color": "#fff", "line-blur": 0.75 },
  },
  countyData: {
    "id": "countyData",
    "source": "countyData",
    "source-layer": "counties",
    "type": "fill",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-opacity": [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, 0,
        5.1, 1
      ],      
      "fill-outline-color": "#c6c9ca",
      "fill-color": "hsla(0, 0%, 0%, 0)"
    }
  },
  stateData: {
    "id": "stateData",
    "source": "stateData",
    "source-layer": "state",
    "type": "fill",
    "layout": {
      "visibility": "visible"
    },
    "paint": {
      "fill-outline-color": "#c6c9ca",
      "fill-color": "hsla(0, 0%, 0%, 0)"
    }
  },

}

export const BOUNDARY_LAYER_STYLE_IDS = Object.keys(BOUNDARY_LAYER_STYLES)

// Object.fromEntries(Object.entries(BOUNDARY_LAYER_STYLES)
//   .map(([k, v]) => v.id));
