
// "climateScenarioId": climate scenario label
export const climateScenarios = {
  "85": "Current Climate Trajectory",
  "26": "Aggressive Climate Action"
}

export const climateScenarioTooltipBlurb = (thing) => `Will present day crop varieties and production practices face increased challenges in 2040? This layer shows where conditions for growing ${thing} without irrigation will improve or worsen. Results are shown for projected climate conditions under scenarios of both low and high greenhouse gas emissions`

export const climateScenarioConfig = {
  "crop": {
    label: "Cropland", // label used for labels associated with climate scenarios
    countyDataKeyPrefix: "county_crop", // county layer name prefix
    stateDataKeyPrefix: "state_crop", // state layer name prefix
    text1: "growing cropland without irrigation", // text for dynamic narrative
    text3: "Suitability for cropland", // text for dynamic narrative
    colorInactive: "#1982be",
    colorActive: "lightblue",
    tooltip: climateScenarioTooltipBlurb("crops")
  },
  "corn": {
    label: "Corn", 
    countyDataKeyPrefix: "county_corn", 
    stateDataKeyPrefixKeyPrefix: "state_corn", 
    text1: "growing corn without irrigation", 
    text3: "Suitability for corn", 
    colorInactive: '#364c92',
    colorActive: "darkblue",
    tooltip: climateScenarioTooltipBlurb("corn")
  },
  "wWheat": {
    label: "Winter Wheat",
    countyDataKeyPrefix: "county_wWheat",
    stateDataKeyPrefix: "state_wWheat",
    text1: "growing winter wheat without irrigation",
    text3: "Suitability for winter wheat",
    colorInactive: '#364c92',
    colorActive: "darkblue",
    tooltip: climateScenarioTooltipBlurb("winter wheat")
  },
  "apple": {
    label: "Apples",
    countyDataKeyPrefix: "county_apple",
    stateDataKeyPrefix: "state_apple",
    text1: "growing apples wheat without irrigation",
    text3: "Suitability for apples",
    colorInactive: '#364c92',
    colorActive: "darkblue",
    tooltip: climateScenarioTooltipBlurb("apples")
  },
  // removed: https://github.com/csp-inc/aft2040/issues/59  
  // "pasture_suit": {
  //   label: "Pastureland",
  //   countyDataKeyPrefix: "county_pasture",
  //   stateDataKeyPrefix: "state_pasture",
  //   text1: "growing pasture without irrigation",
  //   text3: "Suitability for pasture",
  //   colorInactive: "#1982be",
  //   colorActive: "lightblue",
  //   tooltip: climateScenarioTooltipBlurb("pastures")
  // },    
  // "range_suit": {
  //   label: "Rangeland",
  //   countyDataKeyPrefix: "county_range",
  //   stateDataKeyPrefix: "state_range",
  //   text1: "growing corn without irrigation",
  //   text3: "Suitability for pasture",
  //   colorInactive: "#1982be",
  //   colorActive: "lightblue",
  //   tooltip: climateScenarioTooltipBlurb("rangeland plants")   
  // },    
}