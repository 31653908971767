import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { MapProvider } from 'react-map-gl';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faShareAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";

//components
import Navbar from "../components/Navbar";
import Map from "../components/Map";
import Footer from "../components/Footer";
import FutureGraphs from "../components/FutDevelopmentGraphs";
import StatsRow from "../components/StatsRow";
import DownloadStateReportButton from "../components/DownloadStateReportButton"
import StatsTextSummary from "../components/StatsTextSummary"
import { LAYER_CONFIG } from '../data/layerConfig'
import aboutModalMarkdownFile from "../docs/about.md"
import AboutModal from "../components/AboutModal";

const appId = "FutureDev";
const appTitle = 'Farms Under Threat 2040';
const aboutModaltitle = `Explore the Future of Farmland`;
const aboutModalBody = ""

const FutureDevPage = () => {

  /********************************************************
   * App State
   */

  /**
   * viewport
   * * zoom/extent of the synchronized map view
   * * controlled by the Map component
   */
  const [viewport, setViewport] = useState({
    latitude: 38.8,
    longitude: -96.0,
    zoom: 3.3,
    bearing: 0,
    pitch: 0,
  });

  /**
   * baseMapId
   * * a string which determines which set of Mapbox source and styles layers is 
   * assembled to create a basemp
   * * controlled by BaseMapSelector >>> MapControl
   */
  const [baseMapId, setBaseMapId] = useState("Present Day");
  const [activeStyleId, setActiveStyleId] = useState("Present Day");

  const [opacitySliderValue, setOpacitySliderValue] = useState(100);
  const [statsData, setStatsData] = useState({});
  const [statisticsData, setStatisticsData] = useState({});
  const [leftMapImage, setLeftMapImage] = useState(null);
  const [rightMapImage, setRightMapImage] = useState(null);
  const [isDownloadTriggered, setIsDownloadTriggered] = useState(false);
  const [leftScenarioIsOpen, setLeftScenarioIsOpen] = useState(true);
  const [rightScenarioIsOpen, setRightScenarioIsOpen] = useState(true);
  const [scenarioDropdownOpen, setScenarioDropdownOpen] = useState(false);

  // const displayTimeSlider = false;
  const displayClimateScenarios = false;
  const leftMap = true;
  const rightMap = true;
  
  const showOverlays = LAYER_CONFIG[appId].overlay.map(o => o.label);

  useEffect(() => {
    localStorage.setItem("FutureDevLoaded", true);
  }, []);

  useEffect(() => {
    if (isDownloadTriggered && leftMapImage && rightMapImage) {
      exportAsImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadTriggered, leftMapImage, rightMapImage]);

  const exportAsImage = () => {
    downloadCompareMapPDF();
    setIsDownloadTriggered(false);
  };

  const downloadCompareMapPDF = async () => {
    const leftMapContainerEL = await document.querySelector(
      "#map-section .left-map .mapboxgl-canvas-container"
    );
    leftMapContainerEL.firstChild.style.display = "none";
    const leftImgEl = document.createElement("img");
    leftImgEl.src = leftMapImage;
    leftMapContainerEL.appendChild(leftImgEl);

    const rightMapContainerEL = await document.querySelector(
      "#map-section .right-map .mapboxgl-canvas-container"
    );
    rightMapContainerEL.firstChild.style.display = "none";
    const rightImgEl = document.createElement("img");
    rightImgEl.src = rightMapImage;
    rightMapContainerEL.appendChild(rightImgEl);

    const pdf = new jsPDF("landscape", "pt", "a4");
    const mapSectionEl = document.getElementById("map-section");
    const mapSectionData = await html2canvas(mapSectionEl);
    const mapSectionImage = mapSectionData.toDataURL("image/png", 1.0);
    const imgProperties = pdf.getImageProperties(mapSectionImage);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(mapSectionImage, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("compare_map.pdf");

    leftMapContainerEL.firstChild.style.display = "block";
    rightMapContainerEL.firstChild.style.display = "block";
    leftMapContainerEL.removeChild(leftImgEl);
    rightMapContainerEL.removeChild(rightImgEl);
  };

  const exportLeftMapHandler = (image) => {
    setLeftMapImage(image);
  };

  const exportRightMapHandler = (image) => {
    setRightMapImage(image);
  };

  const downloadCompareMapHandler = () => {
    setIsDownloadTriggered(true);
  };

  const handleAboutModalClose = () => {
    console.log("handleAboutModalClose", scenarioDropdownOpen)
    if (!scenarioDropdownOpen) {
      setScenarioDropdownOpen(true)
      setLeftScenarioIsOpen(true)
    }
  }

  return (
    <MapProvider >
      <nav>
        <Navbar
          title={"Farms Under Threat 2040"}
          buttonLabel={"ABOUT"}
          appName={"Climate Impacts"}
          includeHowToLink={"https://youtu.be/ODrV-j1uLY4"}
          linkToApp={"/"}
          aboutModalButton={
            <AboutModal
              modalId={"nav-about-modal"}
              buttonLabel={"ABOUT"}
              modalTitle={aboutModaltitle}
              modalBody={aboutModalBody}
              modalBodyMarkdownFile={aboutModalMarkdownFile}
              openByDefault={true}
            // onOpened={console.log("modal opened")}
            // onClosed={console.log("modal closed")}
            // onExit={console.log("modal exited")}
            />
          }
        />
      </nav>
      <Container fluid>

        <Row id="map-section" className="flex-column-reverse flex-lg-row pe-2 pe-sm-0 pe-md-0 pe-lg-0 pe-xl-0">
          <Col lg={6} xs={12} className="map-column">
            <div className='map-wrapper'>
              <Map
                appId={appId}
                // map identification
                mapId={"left-map"}
                leftMap={leftMap}
                // map viewport state (for synchronization)
                viewport={viewport}
                setViewport={setViewport}
                // "basemap" name (used to select subset of layer from seed style fo)
                baseMapId={baseMapId}
                setBaseMapId={setBaseMapId}
                // "activeStyleId" (used to select subset of layers from style for overlays in the Climate app)
                activeStyleId={activeStyleId}
                setActiveStyleId={setActiveStyleId}
                // opacity slider state (for overlays and scenario layers)
                opacitySliderValue={opacitySliderValue}
                setOpacitySliderValue={setOpacitySliderValue}
                // layer overlays config
                overlays={"Water scarcity"}
                showOverlays={showOverlays}
                showWaterScarcity={true}
                // futdev interative layer data handling
                statsData={statsData}
                setStatsData={setStatsData}
                setStatisticsData={setStatisticsData}
                // map print/export
                exportMap={exportLeftMapHandler}
                downloadCompareMap={downloadCompareMapHandler}
                // futdev scenario dropdown config
                scenarioDropdownOpen={leftScenarioIsOpen}
                setScenarioDropdownOpen={setLeftScenarioIsOpen}
                // climate scenario map config
                displayClimateScenarios={displayClimateScenarios}
                defaultDevScenarioId="fut_2040_lc"
                // Custom about component
                mapInfoButton={
                  <AboutModal
                    modalId={"left-map-about-modal"}
                    buttonLabel={<FontAwesomeIcon size={"sm"} icon={faInfo}></FontAwesomeIcon>}
                    modalTitle={aboutModaltitle}
                    modalBody={aboutModalBody}
                    modalBodyMarkdownFile={aboutModalMarkdownFile}
                    openByDefault={false}
                  />
                }
              />
            </div>
          </Col>
          <Col
            lg={12}
            className="mobile-scroll-area">
          </Col>

          <Col lg={6} xs={12} className="map-column">
            <div className='map-wrapper'>
              <Map
                appId={appId}
                mapId={"right-map"}
                rightMap={rightMap}
                defaultDevScenarioId="fut_2040_lc"
                activeStyleId={activeStyleId}
                setActiveStyleId={setActiveStyleId}
                baseMapId={baseMapId}
                setBaseMapId={setBaseMapId}
                opacitySliderValue={opacitySliderValue}
                setOpacitySliderValue={setOpacitySliderValue}
                viewport={viewport}
                setViewport={setViewport}
                overlays={"Water scarcity"}
                showOverlays={showOverlays}
                showWaterScarcity={true}
                statsData={statsData}
                setStatsData={setStatsData}
                setStatisticsData={setStatisticsData}
                displayClimateScenarios={displayClimateScenarios}
                exportMap={exportRightMapHandler}
                scenarioDropdownOpen={rightScenarioIsOpen}
                mapInfoButton={
                  <AboutModal
                    modalId={"right-map-about-modal"}
                    buttonLabel={<FontAwesomeIcon size={"sm"} icon={faInfo}></FontAwesomeIcon>}
                    modalTitle={aboutModaltitle}
                    modalBody={aboutModalBody}
                    modalBodyMarkdownFile={aboutModalMarkdownFile}
                    openByDefault={false}
                  />
                }
              />
            </div>
          </Col>
        </Row>


        <Row id="reporting-wrapper">
          <Col
            lg={{ size: 10, offset: 1 }}
            className="mt-4"
            style={{ minHeight: "350px" }}
          >
            <Container>

              <Row id="get-stuff-wrapper" className="mb-2">
                <Col sm={6}>
                  {/* 
                  // NOTE: download and share disabled until they work
                  <span
                    style={{ background: "none", padding: 0, border: "none" }}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: "rgb(102, 153, 71)",
                        marginRight: "5px",
                        marginTop: "8px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderColor: "white",
                        borderWidth: "5px",
                        height: "25px",
                        width: "25px",
                      }}
                      size="lg"
                      icon={faShareAlt}
                    >
                    </FontAwesomeIcon>
                  </span>
                  <span
                    onClick={downloadCompareMapHandler}
                    style={{
                      background: "none",
                      padding: 0,
                      border: "none",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: "rgb(102, 153, 71)",
                        marginTop: "8px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderColor: "white",
                        borderWidth: "5px",
                        height: "25px",
                        width: "25px",
                      }}
                      size="lg"
                      icon={faFileDownload}
                    >
                    </FontAwesomeIcon>
                  </span> */}
                </Col>
                <Col sm={6}>
                  <div className="float-end">
                    <DownloadStateReportButton statsData={statsData} />
                  </div>
                </Col>
              </Row>

              <Row id="future-graphs-wrapper" className="my-2">
                <Col>
                  <FutureGraphs chartData={statisticsData} />
                </Col>
              </Row>
              <Row id="stats-text-wrapper" className="my-2">
                <Col>
                  <StatsTextSummary statsData={statsData} chartData={statisticsData} />
                </Col>
              </Row>

            </Container>

          </Col>
        </Row>

      </Container>

      <footer>
        <Footer title={appTitle} />
      </footer>
    </MapProvider>
  );
};

export default FutureDevPage;
