import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ClimateImpactPage from "./pages/ClimateImpact";
import FutureDevelopmentPage from "./pages/FutureDev";

import './App.css';

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path="/ClimateImpacts" component={ClimateImpactPage} />
          <Route exact path="/" component={FutureDevelopmentPage} />
          <Route exact path="/FutureDevelopment" component={FutureDevelopmentPage} />
          <Route exact path="/ClimateImpact" component={ClimateImpactPage} />
        </Switch>
    </Router>
  );
}

export default App;
