import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Tooltip
} from "reactstrap";
import FUTLogo from "../images/FUT_RGB.png";
import AFTLogoCircle from "../images/AFTlogo-circle.png";
import AFTLogoFull from "../images/AFTlogo2018_2color_NO_TAG.png";

const SiteNavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  return (
    <>

      <Navbar
        color="light"
        light
        className="p-0"
        id="navbar"
        expand="lg"
        // sticky="top"
        style={{
          color: "#669947",
          backgroundColor: "white",
          zIndex: "2"
        }}
      >

        <span className="navbar-brand">

          {/* >= Medium screen sizes */}
          <div id="navbar-brand-content" className="d-none d-md-inline-block">
            <a href="https://www.farmland.org" target="_blank">
              <img src={AFTLogoFull} alt="American Farmland Trust Logo" height="30" className="align-text-middle m-2" />
            </a>
            <span id="navbar-title" className="navbar-title mx-2 py-1">
              {props.title}
            </span>
            <a href="https://farmland.org/project/farms-under-threat/" target="_blank">
              <img src={FUTLogo} alt="Farms Under Threat project logo" height="50" className="align-text-middle" />
            </a>
          </div>


          {/* < Medium screen sizes */}
          <div id="navbar-brand-content-sm" className="d-inline d-md-none">
            <a href="https://farmland.org/project/farms-under-threat/" target="_blank">
              <img src={FUTLogo} alt="Farms Under Threat project logo" height="50" className="align-text-middle" />
            </a>
            <a href="https://www.farmland.org" target="_blank">
              <img src={AFTLogoFull} alt="American Farmland Trust Logo" height="30" className="align-text-middle m-2" />
            </a>
            <br></br>
            <span id="navbar-title-small" className="x-small navbar-title-sm mx-2">
              {props.title}
            </span>
          </div>

        </span>

        {/* <NavbarText>
          <span className="navbar-title">{props.title}</span>
        </NavbarText> */}

        <NavbarToggler
          onClick={toggle}
          className={"navbarHamburger"}
          style={{ backgroundColor: "darkgray" }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              {/* NOTE: this adds/removes padding for Nav since aboutModalButton is a Button, not a NavLink*/}
              {/* <div className="px-2 d-none d-sm-block">{props.aboutModalButton}</div> */}
              {/* <div className="d-block d-sm-none">{props.aboutModalButton}</div> */}
              {props.aboutModalButton}
            </NavItem>
            
              { props.includeHowToLink ? (
                <NavItem >
                  <NavLink
                    id="nav-howto"
                    style={{ fontWeight: "500", textTransform: "uppercase" }}
                    href="https://youtu.be/ODrV-j1uLY4"
                    target="_blank"
                  >
                    How To
                  </NavLink>
                  {/* <Tooltip
                    isOpen={tooltipOpen}
                    flip
                    target="nav-howto"
                    toggle={() => { setTooltipOpen(!tooltipOpen) }}
                  >
                    Coming Soon
                  </Tooltip>*/}                  
                </NavItem>                 
              ) : (
                null
              )}
            <NavItem>
              <NavLink
                style={{ fontWeight: "500", textTransform: "uppercase" }}
                href="https://farmlandinfo.org/publications/farms-under-threat-2040/"
                target="_blank"
              >
                View Report
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontWeight: "500", textTransform: "uppercase" }}
                href="https://survey123.arcgis.com/share/c683ac7f4c4647f4884a495fc4e58180"
                target="_blank"
              >
                Request Data
              </NavLink>
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span style={{ fontWeight: "500" }}>REPORTS & DATA</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Report 1</DropdownItem>
                <DropdownItem>Report 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Report 3</DropdownItem>
                <DropdownItem>Report 4</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default SiteNavBar;
