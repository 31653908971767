/* eslint-disable no-sequences */
import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShareAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import {Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { climateScenarioConfig } from '../data/climateScenarios'

const baseStyle = {
  // width: '120px',
  // margin: '5px',
  color: 'white'
}

const ClimateButtonBar = (props) => {
  const {
    setMapTitle,
    setClimateOverlayId,
    climateLayerId,
  } = props;

  const [rSelected, setRSelected] = useState(climateLayerId); // button-as-radio-button state

  return (

    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 1,
        // backgroundColor: '#c6c9ca',
      }}
      className="pt-2 pb-1"
    >
      <ButtonGroup>
        {Object.keys(climateScenarioConfig).map((k, i) => {
          let c = climateScenarioConfig[k]
          return (
            <span className='mx-1' key={`$btn-{i}-${k}`}>
              <Button
                
                // color={c.color}
                color="secondary"
                className='px-3'
                outline
                onClick={(event) => (
                  setMapTitle(c.label),
                  setClimateOverlayId(k),
                  setRSelected(k)
                )}
                style={climateLayerId === k ? {backgroundColor:c.colorActive, ...baseStyle} : {backgroundColor:c.colorInactive, ...baseStyle}}
                active={rSelected === k}
                id={`btn-${k}`}
                name={k}
              >
                {c.label}
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`btn-${k}`}
              >
                {c.tooltip}
              </UncontrolledTooltip>
            </span>
          )
        })}
      </ButtonGroup>
    </div>
  )
};

export default ClimateButtonBar;
