import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

const LegendClimate = (props) => {
  // const [dropdownOpen, setOpen] = useState(false);

  // const toggle = () => setOpen(!dropdownOpen);

  const climateLayerClasses = [
    ["#5DB044", "Increased opportunity"],
    ["#DFDBB8", "Remains above average"],
    ["#F3F3F3", "Remains below average"],
    ["#98225B", "Increased threat"],
    ["#D9D6D1", "Excluded counties (>50% of cropland is irrigated)"],
  ];

  return (
    <div className="legend-ci-wrapper map-control-component-wrapper">

      <Button
        id="legend-toggle-climate"
        type="button"
        size="sm"
        color="secondary"
        className="CustomMapControl legend-toggle"
      >
        Legend
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target="legend-toggle-climate"
        container="body"
        delay={10}
        trigger="click"
      // isOpen={true} //NOTE: this doesn't work
      >
        <PopoverHeader className="p-2">
          <span className="small">Change in growing conditions</span>
        </PopoverHeader>
        <PopoverBody className="p-2">

          {climateLayerClasses.map((c, i) => (
            <Row key={`${i}-${c[1]}`}>
              <Col xs={2}>
                <div
                  className="legend-swatch"
                  style={{ backgroundColor: c[0] }}
                ></div>
              </Col>
              <Col xs={10}>
                <div style={{ fontSize: "12px", fontWeight: "700" }}>
                  {c[1]}
                </div>
              </Col>
            </Row>
          ))}

        </PopoverBody>
      </UncontrolledPopover>

    </div>
  );
};

export default LegendClimate;
