import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

const LegendFutDev = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const lulc = [
    ["#C72C16", "Projected urban & highly developed"],
    ["#FA5C00", "Projected low-density residential"],
    ["#69695D", "Existing urban & highly developed"],
    ["#AEAC99", "Existing low-density residential"],
    ["#6CCF51", "Farmland"],
    ["#FFCA57", "Rangeland"],
    ["#F2D6A2", "Federal land (w/ grazing)"],
    ["#C8D696", "Federal land (no grazing)"],
    ["#E6FADC", "Forestland"],
    ["#116589", "Projected sea-level rise"],
    ["#75A8EA", "Existing water bodies"],
    ["#47473F", "Transportation"],
    ["#FBFAEC", "Other"],
    ["#BB88F2", "Projected farmland protection"],
    ["#735395", "Existing farmland protection"]
  ];

  return (
    <div className="legend-fut-dev-wrapper map-control-component-wrapper">

      <Button
        id="legend-toggle-futdev"
        type="button"
        size="sm"
        color="secondary"
        className="CustomMapControl legend-toggle"
      >
        Legend
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target="legend-toggle-futdev"
        container="body"
        delay={10}
        trigger="click"
      // isOpen={true} //NOTE: this doesn't work
      >
        {/* <PopoverHeader className="p-2">
          <span className="small">Landcover</span>
        </PopoverHeader> */}
        <PopoverBody className="p-2">

          {lulc.map((c, i) => (
            <Row key={`${i}-${c[1]}`}>
              <Col xs={2}>
                <div
                  className="legend-swatch"
                  style={{ backgroundColor: c[0] }}
                ></div>
              </Col>
              <Col xs={10}>
                <div style={{ fontSize: "12px", fontWeight: "700" }}>
                  {c[1]}
                </div>
              </Col>
            </Row>
          ))}

        </PopoverBody>
      </UncontrolledPopover>

      {/* <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className="CustomMapControl"
          id="legend-toggle"
          type="button"
          size="sm"
        >
          Legend
        </DropdownToggle>        
        <DropdownMenu
          id={props.id}
          className="px-2"
          container="body"
        >
          
            {lulc.map((c, i) => (
              <Row key={`${i}-${c[1]}`}>
                <Col xs={2}>
                  <div 
                    className="legend-swatch"
                    style={{backgroundColor: c[0]}}
                  ></div>
                </Col>
                <Col xs={10}>
                  <div style={{ fontSize: "12px", fontWeight: "700" }}>
                    {c[1]}
                  </div>
                </Col>
              </Row>
            ))}
        </DropdownMenu>

      </ButtonDropdown> */}

    </div>
  );
};

export default LegendFutDev;
