import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "reactstrap";

import { BASEMAP_CONTROL_CONFIG } from '../data/layerConfig'

const BasemapSelector = (props) => {
  const {
    baseMapId,
    setBaseMapId,
    basemapLookup
  } = props;

  let basemaps = BASEMAP_CONTROL_CONFIG;
  // fallback to default basemaps from config file
  if (basemapLookup !== undefined) {
    basemaps = basemapLookup;
  }

  return (
    // <div className="basemap-selector-wrapper map-control-wrapper">
    <ButtonGroup size="sm" className="basemap-selector-wrapper map-control-component-wrapper">
      {basemaps.map((b, i) => (
        <Button
        key={`${i}-${b[0]}`}
        className="CustomMapControl basemap-selector-button"
        color={baseMapId === b[0] ? "primary" : "secondary"}
        value={b[0]}
        onClick={(evt) => {
          setBaseMapId(b[0]);
        }}
        >
          {b[1]}
        </Button>
      ))}
    </ButtonGroup>
    // </div>
  )
}

export default BasemapSelector