import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

const RenderedMarkdownFromFile = ({importedMarkdownFile}) => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(importedMarkdownFile)
      .then((res) => res.text())
      .then((text) => {
        setMarkdown(text)
      });
  }, []);

  return (
    <ReactMarkdown 
      children={markdown} 
      linkTarget="_blank"
    />
  );
}
export default RenderedMarkdownFromFile