import React from "react";

const CallToAction = () => {
    return (
        <div className="call-to-action-wrapper">
            <p className="call-to-action-text color--green-dark">The future of America's farms and ranches is up to all of us. This tool shows that the choices we make today at the federal, state, and local levels will have a real impact on what agriculture looks like tomorrow. Get the latest updates from AFT to learn more about how you can get involved in the fight to save the land that sustains us all.</p>
            <div className="d-grid gap-2">
                <a className="btn btn-secondary call-to-action-button" href="https://farmland.org/sign-up/?utm_source=csp.appspot&utm_medium=microsite&utm_campaign=FUT2020app" role="button" target="_blank">Sign-Up for Updates</a>
            </div>
        </div>
    )
}

export default CallToAction