import React from "react";
import {Row, Col} from 'reactstrap';


const StatsTextSummary = ({ statsData, chartData }) => {

    let hasData = false;

    
    // check for presence of nested data needed to render
    if (chartData.hasOwnProperty("allState") && chartData.hasOwnProperty("allCounty")) {        
        if (chartData.allState.hasOwnProperty("STATE_NAME") && chartData.allCounty.hasOwnProperty("NAME")) {
            hasData = true
        }
    }

    // console.log(hasData, chartData)

    return (
        hasData ? (
        <div>
            {/* {JSON.stringify(statsData)} 
            {JSON.stringify(chartData)} */}           
            <hr></hr>
            <p>
                Development threatens every state's best agricultural land: <strong className="big color--red-base">{chartData.allState.st_dev_prop}% of {statsData.stateName}'s conversion and {chartData.allCounty.st_dev_prop}% of {statsData.countyName} County's conversion</strong> is projected to occur on the state's best land<sup>2</sup> in our <em>Business as Usual</em> scenario.
            </p>
            <p>
                <strong className="big">Acres of {statsData.stateName}'s best agricultural land<sup>2</sup> projected to be converted, 2016-2040 </strong>
            </p>
            <Row>
            <Col md={{size:10, offset:1}}>
                    <table className="table table-sm table-borderless table-hover stats--table mb-2">
                        <thead>
                        <tr>
                            <th>Scenario</th>
                            <th>{statsData.stateName}</th>
                            <th>{statsData.countyName} County</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Business as Usual</td>
                            <td>{chartData.allState.state_to_dev_bau.toLocaleString('en-US')}</td>
                            <td>{chartData.allCounty.state_to_dev_bau.toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                            <td>Runaway Sprawl</td>
                            <td>{chartData.allState.state_to_dev_rs.toLocaleString('en-US')}</td>
                            <td>{chartData.allCounty.state_to_dev_rs.toLocaleString('en-US')}</td>
                        </tr>
                        <tr>
                            <td>Better Built Cities</td>
                            <td>{chartData.allState.state_to_dev_bbc.toLocaleString('en-US')}</td>
                            <td>{chartData.allCounty.state_to_dev_bbc.toLocaleString('en-US')}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="small text-ce-center"><em><sup>2</sup>Lands with productivity, versatility, and resiliency (PVR) values above the state median. See the <a href="https://csp-fut.appspot.com/downloads/AFT_FUT_PVR_Fact_Sheet.pdf" target="_blank">PVR Fact Sheet</a>.</em></p>
                </Col>
            </Row>


            <p>
                Statewide, <strong className="big color--red-base">{chartData.allState.ag_to_dev_bau.toLocaleString('en-US')} acres</strong> of farmland and rangeland will be converted to urban and highly developed and low-density residential land uses in our <em>Business as Usual</em> scenario. This includes:
            </p>
            <Row>
                <Col xl={{size:4, offset:1}} lg={{size:5, offset:1}} md={{size:8, offset:1}}>
                    <table className="table table-borderless table-hover table-sm">
                        <tbody>
                        <tr>
                            <td><span className="table-xs-td caps big strong color--green-dark">CROPLAND:</span></td>
                            <td><span className="table-xs-td big strong color--green-dark float-end">{chartData.allState.crop_to_dev_bau.toLocaleString('en-US')} acres</span></td>
                        </tr>
                        <tr>
                            <td><span className="table-xs-td caps big strong color--green-light">PASTURELAND:</span></td>
                            <td><span className="table-xs-td big strong color--green-light float-end">{chartData.allState.pasture_to_dev_bau.toLocaleString('en-US')} acres</span></td>
                        </tr>
                        {/* {((chartData.allState.range_to_dev_bau) > 0) ? ( */}
                        <tr>
                            <td><span className="table-xs-td caps big strong color--gold-base">RANGELAND:</span></td>
                            <td><span className="table-xs-td big strong color--gold-base float-end">{chartData.allState.range_to_dev_bau.toLocaleString('en-US')} acres</span></td>
                        </tr>
                        {/* ) : (null)} */}
                        <tr>
                            <td><span className="table-xs-td caps big strong color--brown-base">WOODLAND<sup>3</sup>:</span></td>
                            <td><span className="table-xs-td big strong color--brown-base float-end">{chartData.allState.woodland_to_dev_bau.toLocaleString('en-US')} acres</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="small"><em><sup>3</sup>Woodland associated with farms</em></p>         
                </Col>
            </Row>
            
            <p><strong className="big">LDR paves the way for further development.</strong> Agricultural land that was in LDR areas in 2016 is <strong className="big color--orange-base">{chartData.allState.ldr_multiplier_bau} times more likely</strong> to be converted to UHD by 2040, compared to other agricultural land in {statsData.stateName}.</p>
        </div>
        ) : (
            null
        )
    )
}

export default StatsTextSummary;