import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";
import { Container, Col, Row } from "reactstrap";

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};

const xAxisTickFormatter = (value) => value?.toLocaleString("en-US");

const renderLegend = ({ color, label }) => {
  return (
    <div style={{ display: "flex", margin: "20px" }}>
      <span
        style={{
          width: "20px",
          height: "20px",
          background: color,
          border: "1px solid #000",
          marginRight: "10px",
        }}
      />
      <span>{label}</span>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>
          <strong>{label}</strong>
        </p>
        <p className="label">{`${payload[0].name}: ${xAxisTickFormatter(
          payload[0].value
        )}`}</p>
        <p className="label">{`${payload[1].name}: ${xAxisTickFormatter(
          payload[1].value
        )}`}</p>
        <p className="label">{`Total acres converted: ${xAxisTickFormatter(
          payload[0].value + payload[1].value
        )}`}</p>
      </div>
    );
  }

  return null;
};

const Graphs = ({ chartData }) => {

  let hasData = false;

    
  // check for presence of nested data needed to render
  if (chartData.hasOwnProperty("allState") && chartData.hasOwnProperty("allCounty")) {        
      if (chartData.allState.hasOwnProperty("STATE_NAME") && chartData.allCounty.hasOwnProperty("NAME")) {
          hasData = true
      }
  }

  const stateChartData = [
    {
      name: "Business as Usual",
      "Urban and highly developed": Math.round(
        chartData.state?.ag_to_uhd_bau || 0
      ),
      "Low-density residential": Math.round(
        chartData.state?.ag_to_ldr_bau || 0
      ),
    },
    {
      name: "Runaway Sprawl",
      "Urban and highly developed": Math.round(
        chartData.state?.ag_to_uhd_rs || 0
      ),
      "Low-density residential": Math.round(chartData.state?.ag_to_ldr_rs || 0),
    },
    {
      name: "Better Built Cities",
      "Urban and highly developed": Math.round(
        chartData.state?.ag_to_uhd_bbc || 0
      ),
      "Low-density residential": Math.round(
        chartData.state?.ag_to_ldr_bbc || 0
      ),
    },
  ];

  const countyChartData = [
    {
      name: "Business as Usual",
      "Urban and highly developed": Math.round(
        chartData.county?.ag_to_uhd_bau || 0
      ),
      "Low-density residential": Math.round(
        chartData.county?.ag_to_ldr_bau || 0
      ),
    },
    {
      name: "Runaway Sprawl",
      "Urban and highly developed": Math.round(
        chartData.county?.ag_to_uhd_rs || 0
      ),
      "Low-density residential": Math.round(
        chartData.county?.ag_to_ldr_rs || 0
      ),
    },
    {
      name: "Better Built Cities",
      "Urban and highly developed": Math.round(
        chartData.county?.ag_to_uhd_bbc || 0
      ),
      "Low-density residential": Math.round(
        chartData.county?.ag_to_ldr_bbc || 0
      ),
    },
  ];

  return (
    
    (hasData) ? (
    <div>
      <Row>
        <Col xs={12}>
          <h4 align="center" className="mb-4">
            {(chartData.allState.range_to_dev_bau > 0) ? (
              <span>Projected Conversion of Farmland<sup>1</sup> and Rangeland from 2016-2040</span>
            ) : (
              <span>Projected Conversion of Farmland<sup>1</sup> from 2016-2040</span>
            )}
          </h4>
        </Col>
        {chartData.state ? (
          <Col xs={12} md={6}>
            <Typography
              variant="h5"
              align="center"
              style={{ paddingLeft: "120px" }}
            >
              {chartData.state?.STATE_NAME}
            </Typography>
            <div style={{ display: "flex" }}>
              <ResponsiveContainer 
                height={250} 
                width={"90%"}
              >
                <BarChart
                  layout="vertical"
                  data={stateChartData}
                  
                  // margin={{ left: 50, right: 50 }}
                >
                  <XAxis type="number" tickFormatter={xAxisTickFormatter} />
                  <YAxis
                    type="category"
                    dataKey="name"
                    stroke="#000000"
                    fontSize="14"
                    width={60}
                  />
                  <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }} />
                  <Bar
                    dataKey="Urban and highly developed"
                    fill="#C72C16"
                    stackId="a"
                  >
                    {/* <LabelList
                      dataKey="Urban and highly developed"
                      position="center"
                      content={renderCustomizedLabel}
                    /> */}
                  </Bar>
                  <Bar
                    dataKey="Low-density residential"
                    fill="#FA5C00"
                    stackId="a"
                  >
                    {/* <LabelList
                      dataKey="Low-density residential"
                      position="center"
                      content={renderCustomizedLabel}
                    /> */}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "25px 0",
                }}
              >
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.state?.ag_to_dev_bau)
                  )} acres`}
                </p>
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.state?.ag_to_dev_rs)
                  )} acres`}
                </p>
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.state?.ag_to_dev_bbc)
                  )} acres`}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span>Acres converted</span>
            </div>
          </Col>
        ): (null)}
        <Col
          xs={12}
          className="mobile-scroll-area">
        </Col>
        {chartData.county ? (
          <Col xs={12} md={6}>
            <Typography
              variant="h5"
              align="center"
              style={{ paddingLeft: "120px" }}
            >{`${chartData.county?.NAME} County`}</Typography>

            <div style={{ display: "flex" }}>
              <ResponsiveContainer 
                height={250} 
                width={"90%"}
              >
                <BarChart
                  layout="vertical"
                  data={countyChartData}
                  // margin={{ left: 50, right: 50 }}
                >
                  <XAxis type="number" tickFormatter={xAxisTickFormatter} />
                  <YAxis
                    type="category"
                    dataKey="name"
                    stroke="#000000"
                    fontSize="14"
                    width={60}
                  />
                  <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }}/>
                  <Bar
                    dataKey="Urban and highly developed"
                    fill="#C72C16"
                    stackId="a"
                  >
                    {/* <LabelList
                      dataKey="Urban and highly developed"
                      position="center"
                      content={renderCustomizedLabel}
                    /> */}
                  </Bar>
                  <Bar
                    dataKey="Low-density residential"
                    fill="#FA5C00"
                    stackId="a"
                  >
                    {/* <LabelList
                      dataKey="Low-density residential"
                      position="center"
                      content={renderCustomizedLabel}
                    /> */}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "25px 0",
                }}
              >
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.county?.ag_to_dev_bau)
                  )} acres`}
                </p>
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.county?.ag_to_dev_rs)
                  )} acres`}
                </p>
                <p style={{ whiteSpace: "nowrap" }}>
                  {`${xAxisTickFormatter(
                    Math.round(chartData.county?.ag_to_dev_bbc)
                  )} acres`}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span>Acres converted</span>
            </div>
          </Col>
        ) : (<p>No county-level data available in this location.</p>)}
      </Row>
      <Row>
      <Col>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {renderLegend({
          label: "Urban and highly developed",
          color: "#C72C16",
        })}
        {renderLegend({
          label: "Low-density residential",
          color: "#FA5C00",
        })}
      </div>
      <p className="mb-0"><span className="symbol-uhd">Urban and highly developed (UHD)</span> land use includes commercial, industrial, and moderate-to-high density residential areas.</p>
      <p><span className="symbol-ldr">Low-density residential (LDR)</span> land use includes scattered subdivisions and large-lot housing, which fragment the agricultural land base and limit production.</p>
      <p className="small"><em><sup>1</sup>Farmland includes cropland, pasture and woodland associated with farms.</em></p>
      </Col>
      </Row>   
    </div>   

    ) : (
      <Row>
        <Col md={{offset:3, size:6}}>
          <p className="lead text-center">Select a state and county from the map to see Projected Conversion of Farmland and Rangeland from 2016-2040</p>
        </Col>
      </Row>
    )
  
)};

export default Graphs;
