export const devScenarioConfig = {
    "baseline": {

    },
    "futureDev": {
        bau_2040: {
            label: "Business as Usual",
            backgroundColor: "#364c92",
            tooltip: "Development follows recent patterns. Poorly planned development and low-density residential sprawl continue to rapidly convert farmland and ranchland."
        },
        rs_2040: {
            label: "Runaway Sprawl",
            backgroundColor: "#1982be",
            tooltip: "Development becomes even less efficient than in Business as Usual. Low-density housing sweeps across the countryside, displacing farmers and ranchers."
        },
        bbc_2040: {
            label: "Better Built Cities",
            backgroundColor: "#735395",
            tooltip: "Policymakers and land-use planners promote compact development and reduce sprawl, saving irreplaceable farmland and ranchland from conversion."
        }
    },
    "farmlandProtectionCities": {
        "boise_id": {
            "label": "Boise, ID",
            "lat": 43.618881,
            "lon": -116.215019
        },
        "pittsfield_ma": {
            "label": "Pittsfield, MA",
            "lat": 42.450085,
            "lon": -73.245382
        },
        "fresno_ca": {
            "label":"Fresno, CA",
            "lat": 36.746841,
            "lon": -119.772591
        },
        "washington_dc": {
            label: "Washington, D.C.",
            "lat": 38.9047,
            "lon": -77.0164
        },
        "southeast_wi": {
            label:"Southeast WI",
            "lat": 43.0186,
            "lon": -88.259773
        },
        "austin_tx": {
            label: "Austin, TX",
            "lat": 30.266666,
            "lon": -97.73333
        },
        "atlanta_ga": {
            label: "Atlanta, GA",
            "lat": 33.753746,
            "lon": -84.38633
        },
        "buffalo_ny": {
            label: "Buffalo, NY",
            "lat": 42.88023,
            "lon": -78.878738
        },
        "chicago_il": {
            label: "Chicago, IL",
            "lat": 41.881832,
            "lon": -87.623177
        },
        "raleigh_nc": {
            label: "Raleigh, NC",
            "lat": 35.787743,
            "lon": -78.644257
        }
    }
}